var render = function () {
  var _vm$form$rft_name;
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', [_c('p', [_vm._v(" This is the main parameters for the tender including contact person, brief description, RFT type, start date, end date and contract period. ")]), _c('el-form', {
    directives: [{
      name: "loading",
      rawName: "v-loading",
      value: _vm.isLoading,
      expression: "isLoading"
    }],
    attrs: {
      "label-width": "180px"
    }
  }, [!_vm.viewOnly ? _c('div', {
    staticClass: "col-md-12 text-right"
  }, [_vm.isBuyer ? _c('b-button', {
    directives: [{
      name: "permission",
      rawName: "v-permission",
      value: 'rft-award',
      expression: "'rft-award'"
    }],
    attrs: {
      "variant": "outline-secondary"
    },
    on: {
      "click": function ($event) {
        $event.preventDefault();
        return _vm.cancelRft();
      }
    }
  }, [_c('mdicon', {
    attrs: {
      "name": "file-cancel-outline",
      "size": 18
    }
  }), _vm._v(" Cancel RFT ")], 1) : _vm._e()], 1) : _vm._e(), _vm.isSupplier ? _c('el-form-item', {
    attrs: {
      "label": "Organisation"
    }
  }, [_vm._v(" " + _vm._s(_vm.form.organisation_name) + " ")]) : _vm._e(), _c('el-form-item', {
    attrs: {
      "label": "RFT Name"
    }
  }, [_vm._v(" " + _vm._s((_vm$form$rft_name = _vm.form.rft_name) !== null && _vm$form$rft_name !== void 0 ? _vm$form$rft_name : "N/A") + " ")]), _c('el-form-item', {
    attrs: {
      "label": "Contact Person"
    }
  }, [_vm._v(" " + _vm._s(_vm.form.contact_person) + " ")]), _c('el-form-item', {
    attrs: {
      "label": "Contact Phone"
    }
  }, [_vm._v(" " + _vm._s(_vm.form.contact_phone) + " ")]), _c('el-form-item', {
    attrs: {
      "label": "Contact Email"
    }
  }, [_vm._v(" " + _vm._s(_vm.form.contact_email) + " ")]), _c('el-form-item', {
    attrs: {
      "label": "Brief Description"
    }
  }, [_vm._v(" " + _vm._s(_vm.form.description) + " ")]), _c('el-form-item', {
    attrs: {
      "label": "Product Category"
    }
  }, [_vm._v(" " + _vm._s(_vm.form.productType ? _vm.form.productType.display : "N/A") + " ")]), _c('el-form-item', {
    attrs: {
      "label": "RFT Type"
    }
  }, [_vm._v(" " + _vm._s(_vm.form.type) + " ")]), _c('el-form-item', {
    attrs: {
      "label": "RFT Start Date"
    }
  }, [_vm._v(" " + _vm._s(_vm.form.rft_start_on) + " ")]), _c('el-form-item', {
    attrs: {
      "label": "RFT End Date"
    }
  }, [_vm._v(" " + _vm._s(_vm.form.rft_end_on) + " ")]), _c('el-form-item', {
    attrs: {
      "label": "Contract Period From"
    }
  }, [_vm._v(" " + _vm._s(_vm.form.contract_period_from) + " ")]), _c('el-form-item', {
    attrs: {
      "label": "Contract Period To"
    }
  }, [_vm._v(" " + _vm._s(_vm.form.contract_period_to) + " ")]), _vm.form.max_items_in_bundle ? _c('el-form-item', {
    attrs: {
      "label": "Maximum Items in Bundle"
    }
  }, [_vm._v(" " + _vm._s(_vm.form.max_items_in_bundle) + " ")]) : _vm._e(), _vm.isBuyer && _vm.form.feedback ? _c('el-form-item', {
    attrs: {
      "label": "Feedback"
    }
  }, [_vm._v(" " + _vm._s(_vm.form.feedback) + " ")]) : _vm._e(), _c('el-form-item', {
    attrs: {
      "label": "Public Page"
    }
  }, [_c('el-switch', {
    attrs: {
      "value": _vm.form.is_public,
      "disabled": ""
    }
  }), _vm.form.is_public && _vm.publicUrl ? _c('a', {
    staticClass: "ml-2",
    attrs: {
      "href": _vm.publicUrl,
      "target": "_BLANK"
    }
  }, [_vm._v(" " + _vm._s(_vm.publicUrl) + " ")]) : _vm._e(), _vm.form.is_public && _vm.publicUrl ? _c('el-tooltip', {
    staticClass: "item ml-2",
    attrs: {
      "content": "This url will work after this RFT is published",
      "placement": "top"
    }
  }, [_c('i', {
    staticClass: "el-icon-info"
  })]) : _vm._e()], 1)], 1)], 1);
}
var staticRenderFns = []

export { render, staticRenderFns }